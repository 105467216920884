<script setup>
import { commonValues } from '@/config/common.js';
import { DEFAULT_CURRENCY } from '@/config/currencies.js';
import { WdsIcon, WdsRating } from '@wds/components';

const props = defineProps({
  image: {
    type: String,
    required: false,
    default: '',
  },
  name: {
    type: String,
    required: true,
    default: '',
  },
  source: {
    type: String,
    default: '',
  },
  location: {
    type: String,
    required: false,
    default: '',
  },
  rating: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  price: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  link: {
    type: String,
    required: false,
    default: '',
  },
});

const emit = defineEmits(['destinationCardClick']);

const { useFormatter } = useFormatters();

const getCommonValues = computed(() => {
  return commonValues;
});
const getComponentTag = computed(() => {
  return props.link ? 'a' : 'div';
});
const getAnchorLink = computed(() => {
  return props.link || null;
});
const showRating = computed(() => {
  return props.rating?.score;
});
const destinationPrice = computed(() => {
  const priceValue = props.price?.value || 0;
  const priceCurrency = props.price?.currency || DEFAULT_CURRENCY.code;
  return priceValue > 0 ? useFormatter.formatCurrency(priceValue, priceCurrency) : null;
});

const handleDestinationCardClick = function () {
  return props.link && emit('destinationCardClick');
};
</script>

<template>
  <component
    :is="getComponentTag"
    :href="getAnchorLink"
    class="destination-card-wrapper"
    :title="name"
    @click="handleDestinationCardClick"
  >
    <div
      class="destination-card-image"
      :style="image ? `background-image: url(${image})` : null"
    >
      <img
        v-if="image"
        :src="getCommonValues.TRANSPARENTPIXEL"
        :alt="name"
        :title="name"
        width="1px"
        height="1px"
        loading="lazy"
      />
      <WdsIcon
        v-else
        asset="images"
      />
    </div>
    <div class="destination-card-info">
      <strong class="destination-info-name">{{ name }}</strong>
      <div class="destination-info-city-and-rating">
        <template v-if="location">
          <WdsIcon asset="location-pin" />
          <span class="destination-info-city">{{ location }}</span>
        </template>
        <WdsRating
          v-if="showRating"
          v-bind="rating"
        />
      </div>
      <div
        v-if="destinationPrice"
        class="destination-info-pricing"
      >
        {{ source === 'average-city-prices' ? '' : $t('t_PRICESFROM') }} {{ destinationPrice }}
      </div>
    </div>
  </component>
</template>

<style scoped lang="scss">
.destination-card-wrapper {
  display: flex;
  gap: $wds-spacing-m;
  color: $wds-color-ink-darker;
  fill: $wds-color-ink-darker;
  border-radius: $wds-border-radius-m;
  background-color: $wds-color-white;

  &:focus-visible {
    box-shadow:
      0 0 0 wds-rem(6px) $wds-color-white,
      0 0 0 wds-rem(8px) $wds-color-ink-darker;
    text-decoration: none;
  }

  .destination-card-image {
    flex: 0 0 wds-rem(112px);
    height: wds-rem(112px);
    background-color: $wds-color-ink-lighter;
    background-size: cover;
    border-radius: $wds-border-radius-m;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: $wds-color-ink-light;

    :deep(svg) {
      width: $wds-spacing-xxl;
      height: $wds-spacing-xxl;
    }
  }

  .destination-card-info {
    flex-grow: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $wds-spacing-xs;

    @include body-1-reg;

    .destination-info-name {
      @include body-1-bld;
      @include truncate-text(2, $wds-line-height-title-5);
    }

    .destination-info-city-and-rating {
      display: inline-flex;
      align-items: center;
      gap: $wds-spacing-xs;

      .destination-info-city {
        @include truncate-text;
      }
    }

    .destination-info-pricing {
      display: inline-flex;
      align-items: center;
      gap: $wds-spacing-s;
      color: $wds-color-ink;

      @include body-1-reg;
    }
  }
}
</style>
